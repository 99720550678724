import request from "../utils/request";

//企业政策列表查询
export function getPolicyList(data) {
  return request({
    url: "/api/enterprise_standards/policyList",
    method: "get",
    params: data,
  });
}

//上传文件
export function enterpriseUpload(data) {
  return request({
    url: "/api/public/enterpriseUpload",
    method: "post",
    data,
  });
}

//企业提交上传文件
export function savePolicyFile(data) {
  return request({
    url: "/api/enterprise_standards/savePolicyFile",
    method: "post",
    data,
  });
}

//文件资料删除
export function policyDel(data) {
  return request({
    url: "/api/enterprise_standards/policyDel",
    method: "get",
    params: data,
  });
}

//企业标准管理列表请求
export function enterpriseStandardList(data) {
  return request({
    url: "/api/enterprise_standards/enterpriseStandardList",
    method: "get",
    params: data,
  });
}

//企业标准编制项目状态新增
export function statusAddList(data) {
  return request({
    url: "/api/enterprise_standards/statusAdd",
    method: "post",
    data,
  });
}

//企业标准编制查询状态列表
export function statusList(data) {
  return request({
    url: "/api/enterprise_standards/statusList",
    method: "get",
    params: data,
  });
}

//企业标准编制修改状态列表
export function statusEditData(data) {
  return request({
    url: "/api/enterprise_standards/statusEdit",
    method: "post",
    data,
  });
}

// 企业标准编制删除状态列表
export function statusDelData(data) {
  return request({
    url: "/api/enterprise_standards/statusDel",
    method: "post",
    data,
  });
}

//查询保存的时间
export function standardTimeList(data) {
  return request({
    url: "/api/standard/standardTimeList",
    method: "get",
    params: data,
  });
}

//系统设置-时间管理保存
export function standardTimeStore(data) {
  return request({
    url: "/api/standard/standardTimeStore",
    method: "post",
    data,
  });
}

//请求企业字典项
export function organizeList(data) {
  return request({
    url: "/api/organizations/list",
    method: "get",
    params: data,
  });
}

// 公示公号查询列表
export function publishList(data) {
  return request({
    url: "/api/news/list",
    method: "get",
    params: data,
  });
}

// 公示公号暂存
export function cacheOrganize(data) {
  return request({
    url: "/api/news/cache",
    method: "post",
    data,
  });
}

// 查询暂存的数据
export function getCacheData(data) {
  return request({
    url: "/api/news/getcache",
    method: "get",
    params: data,
  });
}

// 公示公号发布
export function publishAdd(data) {
  return request({
    url: "/api/news/store",
    method: "post",
    data,
  });
}

// 公示公号修改
export function publishEdit(data) {
  return request({
    url: "/api/news/edit",
    method: "post",
    data,
  });
}

// 公示公号删除
export function publishDel(data) {
  return request({
    url: "/api/news/delete",
    method: "get",
    params: data,
  });
}

// 公示公号撤回
export function publishClose(data) {
  return request({
    url: "/api/news/close",
    method: "post",
    data,
  });
}

// 公示公号审核
export function publishCheck(data) {
  return request({
    url: "/api/news/check",
    method: "post",
    data,
  });
}

// 测试审核
export function examineNews(data) {
  return request({
    url: "/api/news/examine",
    method: "post",
    data,
  });
}

// 发布新闻审核
export function publishNews(data) {
  return request({
    url: "/api/news/publish",
    method: "post",
    data,
  });
}

// 公示公号查询
export function queryInfo(data) {
  return request({
    url: "/api/news/info",
    method: "get",
    params: data,
  });
}

// 添加企业信息
export function organizationsStore(data) {
  return request({
    url: "/api/organizations/store",
    method: "post",
    data,
  });
}


// 公示公号查询企业
export function organizationsInfo(data) {
  return request({
    url: "/api/organizations/info",
    method: "get",
    params: data,
  });
}

//标准文件列表
export function standardsData(data) {
  return request({
    url: "/api/standard/enterpriseLists",
    method: "get",
    params: data,
  });
}

// 标准文件新增
export function standardAdd(data) {
  return request({
    url: "/api/enterprise_standards/standardSave",
    method: "post",
    data,
  });
}

// 标准文件删除
export function standardDel(data) {
  return request({
    url: "/api/enterprise_standards/standardDel",
    method: "post",
    data,
  });
}

// 标准文件修改
export function standardModify(data) {
  return request({
    url: "/api/enterprise_standards/standardEdit",
    method: "post",
    data,
  });
}

// 标准文件回显
export function standardInfo(data) {
  return request({
    url: "/api/standard/info",
    method: "get",
    params: data,
  });
}

//标签新增
export function standLabelAdd(data) {
  return request({
    url: "/api/enterprise_standards/labelAdd",
    method: "post",
    data,
  });
}

//标签展示
export function queryLabels(data) {
  return request({
    url: "/api/enterprise_standards/labelList",
    method: "get",
    params: data,
  });
}

//修改标签
export function labelEdit(data) {
  return request({
    url: "/api/enterprise_standards/labelEdit",
    method: "post",
    data,
  });
}

//删除标签
export function labelDelete(data) {
  return request({
    url: "/api/enterprise_standards/labelDel",
    method: "post",
    data,
  });
}

//标签查询
export function labelSelectData(data) {
  return request({
    url: "/api/enterprise_standards/labelSelect",
    method: "get",
    params: data,
  });
}

//标准文本新增标签
export function standardLabelAdd(data) {
  return request({
    url: "/api/enterprise_standards/enterpriseLabelAdd",
    method: "post",
    data,
  });
}

export function enterpriseNewAdd(data) {
  return request({
    url: "/api/enterprise_standards/enterpriseNewAdd",
    method: "post",
    data,
  });
}
